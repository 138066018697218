<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Vui lòng chọn" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Thỏa thuận nạp tiền người dùng</h2>
		<p>Thỏa thuận dịch vụ nạp tiền này (sau đây gọi là "Thỏa thuận này") do bạn ký kết với nền tảng UniLive thuộc PIX
			Technology PTE.LTD; Nền tảng sẽ cung cấp Dịch vụ cho bạn (sau đây gọi là "Dịch vụ Starbean" hoặc "Dịch vụ") phù
			hợp với thỏa thuận này và các quy tắc hoạt động được ban hành theo thời gian.</p>
		<p>Để cung cấp dịch vụ tốt hơn cho người dùng, vui lòng yêu cầu người sử dụng dịch vụ (tức là người sử dụng tài
			khoản
			đặt hàng mua "Việt", sau đây gọi là "bạn") đọc kỹ và hiểu đầy đủ Thỏa thuận này trước khi bắt đầu sử dụng
			dịch vụ, trong đó, các điều khoản miễn hoặc hạn chế trách nhiệm của nền tảng, vui lòng tập trung đọc.</p>
		<p>Nếu bạn không đồng ý với Thỏa thuận này, vui lòng không thực hiện các bước tiếp theo dưới bất kỳ hình thức nào
			(bao gồm nhưng không giới hạn ở việc nhấp vào nút hoạt động như nạp tiền, thực hiện thanh toán) và sử dụng Dịch
			vụ.</p>
		<p>
			Nền tảng cấm trẻ vị thành niên sử dụng dịch vụ Việt. Nền tảng này có thiện chí nhắc nhở rằng nếu bạn là người
			giám hộ của trẻ vị thành niên, bạn phải chịu trách nhiệm giám hộ đối với trẻ vị thành niên mà bạn giám hộ, vui
			lòng bật Chế độ thanh thiếu niên và/hoặc các công cụ bảo vệ trẻ vị thành niên khác khi trẻ vị thành niên sử dụng
			các sản phẩm và dịch vụ liên quan của Nền tảng, giám sát, hướng dẫn trẻ vị thành niên sử dụng đúng các sản phẩm và
			dịch vụ liên quan, đồng thời tăng cường hạn chế và quản lý các phương thức thanh toán trực tuyến, cùng nhau tạo
			môi trường tốt cho trẻ vị thành niên phát triển lành mạnh.
		</p>
		<h3>Dịch vụ</h3>
		<p>
			1. "Tinh Đậu" là công cụ ảo mà nền tảng cung cấp cho bạn, chỉ giới hạn trong việc tiêu dùng liên quan trong nền
			tảng. Sau khi bạn mua "Tinh Đậu", bạn có thể sử dụng, nhận được hàng hóa hoặc dịch vụ của mặt
			bằng theo hướng dẫn và hướng dẫn của các trang liên quan của mặt bằng, bao gồm nhưng không giới hạn
			trong dịch vụ thưởng thức trực tiếp (người dùng có thể trả đủ giá cho các dịch vụ như biểu diễn
			trực tiếp thông qua phương thức tặng quà ảo cho người phát sóng), v. v. Để tránh nghi ngờ, dịch vụ
			Tinh Đậu là công nghệ mạng tổng thể và dịch vụ liên quan do người dùng trả tiền, mặt bằng tiếp
			tục cung cấp cho người dùng, dịch vụ Tinh Đậu không phải dịch vụ thanh toán mạng, "Tinh Đậu" cũng
			không phải là vé tiền tệ, tiền ảo hoặc chứng từ tiền ứng phó, không có giá trị tiền tệ, giá
			trị ứng phó.
		</p>
		<p>2.Bạn chỉ có thể sử dụng "Việt" cho các mục đích đã thỏa thuận theo Thỏa thuận này sau khi mua và không được
			sử
			dụng ngoài phạm vi sản phẩm/dịch vụ do Công ty cung cấp hoặc chuyển giao giữa các tài khoản UniLive khác nhau.</p>
		<p>
			3. Bạn nên mua "Tinh Đậu" thông qua kênh chính thức do nền tảng chỉ định, nền tảng không xác nhận bất cứ kênh nào
			không được công ty ủy quyền. Nếu bạn sử dụng các kênh không chính thức để mua Starbean, Nền tảng không thể đảm bảo
			rằng Starbean sẽ truy cập thuận lợi vào tài khoản của bạn và các hành vi như vậy cũng có thể đi kèm với rủi ro lừa
			đảo, rửa tiền, gây tổn thất hoặc thiệt hại khó có thể khắc phục cho bạn, Nền tảng và các bên thứ ba liên quan. Do
			đó, việc mua hàng qua các kênh không chính thức sẽ bị coi là vi phạm, tại thời điểm này Nền tảng có quyền khấu trừ
			hoặc làm trống "Tinh Đậu" trong tài khoản của bạn, hạn chế toàn bộ hoặc một phần chức năng của tài khoản của bạn,
			cho đến khi khóa vĩnh viễn tài khoản của bạn, bạn phải tự chịu trách nhiệm về tổn thất của mình do đó; Bạn phải
			chịu trách nhiệm bồi thường trong trường hợp mất Nền tảng hoặc bên thứ ba khác do vi phạm thỏa thuận đã nêu ở
			trên. Nếu bạn mua thông qua các kênh như cửa hàng chính thức được mở trên nền tảng của bên thứ ba như Thiên Miêu,
			nền tảng sẽ xác định người sử dụng dịch vụ thông qua thông tin người sử dụng tài khoản "Tinh Đậu" mà bạn chỉ định
			và/hoặc thỏa thuận khác giữa bạn và công ty (nếu có).
		</p>
		<p>
			4. Chi phí mua "Tinh Đậu" của bạn sẽ do công ty hoặc đối tác do công ty chỉ định thu. Nền tảng đặc biệt nhắc nhở
			bạn rằng các nhà cung cấp dịch vụ có liên quan trên các kênh mua khác nhau có thể tính phí dịch vụ kênh khi bạn
			trả tiền theo chiến lược hoạt động của riêng họ, điều này có thể dẫn đến sự khác biệt về số tiền bạn phải trả cho
			cùng một lượng "Starbean" hoặc số lượng "Starbean" bạn phải trả cùng một khoản phí thông qua các kênh khác nhau,
			tùy thuộc vào hiển thị trang khi bạn mua "Starbean". Xin ông chú ý xác nhận thông tin trang web liên quan và lựa
			chọn hợp lý kênh mua sắm "Tinh Đậu".
		</p>
		<p>5. Nếu bạn muốn mua "Tinh Đậu" cho tài khoản của người khác, nền tảng sẽ phát số lượng "Tinh Đậu" tương ứng đến
			tài
			khoản bạn chỉ định, người sử dụng tài khoản này có thể căn cứ vào thỏa thuận này mà sử dụng dịch vụ Tinh Đậu cung
			cấp. Trong trường hợp tranh chấp phát sinh do đó, bạn và người sử dụng tài khoản này tự thương lượng giải quyết,
			Nền
			tảng không cần phải chịu trách nhiệm về việc này với bạn và người sử dụng tài khoản.
		</p>
		<p>1. Nền tảng khởi xướng tiêu dùng lý trí, lượng nhập làm xuất. Hãy chắc chắn rằng bạn mua và sử dụng dịch vụ
			Starbean
			một cách hợp lý theo khả năng chi tiêu của riêng bạn và nhu cầu thực tế, tránh tiêu thụ quá mức.</p>
		<p>
			Số tiền bạn sử dụng để mua Việt sẽ là thu nhập mà bạn có được hợp pháp và có quyền sử dụng; Trong trường hợp
			bạn vi phạm Điều này, bất kỳ tranh chấp hoặc tranh chấp nào phát sinh từ đó bạn sẽ tự giải quyết và chịu hậu quả
			pháp lý, chẳng hạn như thiệt hại do hành động của bạn gây ra cho Nền tảng hoặc bên thứ ba, bạn cũng sẽ phải bồi
			thường đầy đủ. Nếu Nền tảng phát hiện (bao gồm nhưng không giới hạn ở việc chủ động phát hiện, nhận được khiếu nại
			của bên thứ ba hoặc thông báo của cơ quan có thẩm quyền, cơ quan tư pháp) rằng bạn bị nghi ngờ vi phạm thỏa thuận
			nêu trên, Nền tảng có quyền khấu trừ hoặc xóa "Tinh Đậu" trong tài khoản của bạn, hạn chế toàn bộ hoặc một phần
			chức năng của tài khoản của bạn cho đến khi vĩnh viễn phong tỏa tài khoản của bạn; Đồng thời mặt sàn có quyền lưu
			giữ thông tin liên quan và báo cáo với các cơ quan có thẩm quyền, cơ quan tư pháp liên quan.
		</p>
		<p>
			3. Nền tảng nghiêm ngặt chống lại hành vi hướng dẫn, kích thích, kích động người dùng khen thưởng phi lý trí và dụ
			dỗ, xúi giục trẻ vị thành niên khen thưởng bằng thông tin giả mạo. Nếu bạn phát hiện hành vi vi phạm quy
			định ở trên, bạn có thể báo cáo với mặt bằng theo kênh công bố của mặt bằng (dưới bên phải phòng
			phát sóng trực tiếp - báo cáo, mặt bằng tự kiềm chế ngành phát sóng trực tiếp UniLive - báo cáo,
			v. v., mặt bằng sẽ áp dụng biện pháp xử lý theo quy định của pháp luật. Mong các bạn cùng chúng tôi
			xây dựng một hệ sinh thái cộng đồng lành mạnh và trật tự.
		</p>
		<h3>Quyền và nghĩa vụ của bạn</h3>
		<p>Thông tin cá nhân hoặc thông tin bạn cung cấp trong quá trình sử dụng Dịch vụ phải trung thực, chính xác và sẽ hỗ
			trợ và hợp tác với các cơ quan điều tra (nếu cần) theo quy định của pháp luật và theo yêu cầu của Nền tảng.</p>
		<p>
			2. Khi mua "Tinh Đậu", bạn nên cẩn thận lựa chọn và/hoặc nhập thông tin tài khoản/tài khoản của bạn vào hòm thư
			liên kết, "Tinh Đậu" mua số lượng. Nếu bạn nhập lỗi, thao tác không tốt, không hiểu rõ phương thức tính
			phí, v. v. gây ra lỗi tài khoản, lỗi số lượng "Tinh Đậu", v. v., do vậy mà dẫn đến thiệt hại hoặc
			chi phí của bạn, mặt bằng có quyền không bồi thường hoặc bồi thường.
		</p>
		<p>3.Bạn phải bảo quản, sử dụng tài khoản của mình và chịu trách nhiệm về mọi hành động và hậu quả của tài khoản đó.
			Nếu có lỗi xảy ra khi Nền tảng không thể cung cấp Dịch vụ hoặc cung cấp Dịch vụ do bạn gây ra, do đó, Nền tảng
			không
			chịu trách nhiệm pháp lý trừ khi được yêu cầu rõ ràng bởi luật pháp và quy định:</p>
		<p>(1) Tài khoản của bạn không hợp lệ, bị mất, bị đánh cắp, bị cấm;</p>
		<p>(2) tổ chức thanh toán bên thứ ba, tài khoản ngân hàng mà tài khoản của bạn bị ràng buộc đã bị đóng băng, tịch
			thu hoặc xảy ra bất thường khác hoặc bạn đã sử dụng tài khoản không được xác thực hoặc tài khoản không phải của
			bạn;</p>
		<p>(3) Bạn thông báo mật khẩu tài khoản cho người khác hoặc đã cho phép người khác đăng nhập sử dụng tài khoản của
			bạn;</p>
		<p>(4) Các trường hợp khác mà bạn có lỗi cố ý hoặc đáng kể.</p>
		<p>
			Bạn nên sử dụng Dịch vụ một cách hợp pháp và tuân thủ, không sử dụng Dịch vụ cho bất kỳ hành vi phạm pháp nào,
			trái với trật tự công cộng, có hại cho đạo đức xã hội hoặc can thiệp vào hoạt động bình thường của Nền tảng, xâm
			phạm quyền và lợi ích hợp pháp của bên thứ ba, và việc bạn sử dụng Dịch vụ không được vi phạm bất kỳ tài liệu ràng
			buộc nào hoặc các yêu cầu khác (nếu có). Nền tảng đặc biệt nhắc nhở bạn không cho mượn, chuyển nhượng hoặc cung
			cấp tài khoản của bạn cho người khác sử dụng, đề phòng hợp lý hành vi vi phạm thỏa thuận trước đó của người khác
			thông qua tài khoản của bạn để bảo vệ tài khoản và tài sản của bạn.
		</p>
		<p>
			5- Nền tảng cung cấp dịch vụ hoàn tiền tiêu dùng cho trẻ vị thành niên theo quy định của pháp luật để bảo đảm
			quyền và lợi ích hợp pháp của trẻ vị thành niên và người giám hộ; Bạn không nên sử dụng Dịch vụ cho các mục đích
			bất hợp pháp hoặc theo cách không phù hợp, bao gồm nhưng không giới hạn ở việc người lớn mạo danh trẻ vị thành
			niên để lừa tiền hoàn lại, gây ra trẻ vị thành niên để yêu cầu hoàn lại tiền sau khi chi tiêu, v.v. Hành vi nêu
			trên sẽ cấu thành vi phạm nghiêm trọng Thỏa thuận này và Nền tảng có quyền từ chối hoàn lại tiền sau khi được xác
			nhận hợp lý và có quyền giữ bạn chịu trách nhiệm pháp lý hơn nữa theo quy định của pháp luật.
		</p>
		<p>
			Bạn phải cam kết không sử dụng tài khoản độc quyền này cho bất kỳ hoạt động bất hợp pháp nào như rửa tiền hoặc
			chấp nhận bất kỳ yêu cầu bất hợp pháp nào của người khác sử dụng tài khoản độc quyền này để hoàn thành rửa tiền.
			Nếu bạn vi phạm thỏa thuận trên, Nền tảng có quyền thực hiện các biện pháp như đình chỉ dịch vụ và tiến hành điều
			tra theo yêu cầu của các cơ quan có thẩm quyền và/hoặc cơ quan tư pháp có thẩm quyền và không chịu bất kỳ tổn thất
			hoặc thiệt hại nào mà bạn phải chịu.
		</p>
		<p>
			7. Trong quá trình sử dụng dịch vụ này, nếu bạn liên quan đến dịch vụ liên quan do bên thứ ba cung cấp (ví dụ như
			dịch vụ thanh toán......), ngoài việc tuân thủ thỏa thuận của thỏa thuận này, bạn còn cần đồng ý và tuân thủ thỏa
			thuận và quy tắc liên quan của bên thứ ba này, trong bất cứ trường hợp nào, tranh chấp phát sinh đối với bên thứ
			ba và dịch vụ liên quan do bạn tự giải quyết với bên thứ ba này, Nền tảng không phải chịu bất cứ trách nhiệm nào
			đối với bạn hoặc bên thứ ba này.
		</p>
		<p>
			1. Nền tảng có quyền dựa trên các cân nhắc về sửa đổi pháp luật và quy định, yêu cầu của cơ quan quản lý, bảo đảm
			an toàn giao dịch, cập nhật chiến lược hoạt động, thay đổi môi trường thị trường và các khía cạnh khác, thỉnh
			thoảng đặt ra các hạn chế, nhắc nhở liên quan đến dịch vụ "Starbean", bao gồm nhưng không giới hạn ở giới hạn giao
			dịch và/hoặc số lần giao dịch của tất cả hoặc một số người dùng, cấm người dùng cụ thể sử dụng dịch vụ, hoặc tăng
			xác minh giao dịch, v.v.
		</p>
		<p>
			Để đảm bảo an toàn giao dịch, Nền tảng cũng có quyền giám sát việc sử dụng Dịch vụ của bạn, đối với người dùng
			hoặc tài khoản được xác định hợp lý là có rủi ro cao, Nền tảng có thể thực hiện các biện pháp cần thiết để ngăn
			chặn rủi ro mở rộng, đảm bảo tài sản người dùng và an toàn sinh thái của Nền tảng; Các biện pháp cần thiết bao gồm
			khấu trừ hoặc xóa sạch "Tinh Đậu" trong tài khoản của bạn, hạn chế toàn bộ hoặc một phần chức năng tài khoản của
			bạn, phong tỏa tài khoản của bạn trong thời gian ngắn hoặc vĩnh viễn.
		</p>
		<p>
			Khi nền tảng phát hiện ra lỗi xử lý do lỗi hệ thống hoặc bất kỳ lý do nào khác, cho dù đó là vì lợi ích của nền
			tảng hay vì lợi ích của bạn, nền tảng có quyền sửa lỗi đó. Tại thời điểm này, nếu bạn thực sự nhận được ít hơn số
			lượng Tinh Đậu mà bạn nên nhận, Nền tảng sẽ bổ sung khoản chênh lệch vào tài khoản của bạn càng sớm càng tốt sau
			khi xác nhận lỗi xử lý; Nếu bạn thực sự nhận được nhiều "Việt" hơn bạn nên nhận, Nền tảng sẽ có quyền khấu
			trừ sự khác biệt trực tiếp từ tài khoản của bạn mà không cần thông báo trước.
		</p>
		<p>4, Nền tảng có quyền thay đổi, gián đoạn, đình chỉ hoặc chấm dứt Dịch vụ này tùy thuộc vào các trường hợp cụ thể
			như an ninh giao dịch, lập kế hoạch hoạt động, luật pháp và quy định quốc gia hoặc yêu cầu của cơ quan quản lý,
			v.v. và Nền tảng không chịu bất kỳ trách nhiệm nào đối với bạn về quyền hoặc vi phạm.</p>
		<h3>Điều khoản miễn trừ trách nhiệm</h3>
		<p>
			1. Bạn hiểu và đồng ý, dịch vụ này được cung cấp theo hiện trạng mà công nghệ và điều kiện hiện có có thể đạt
			được, nền tảng sẽ cố gắng hết sức để cung cấp dịch vụ này cho bạn, đảm bảo tính liên kết và an toàn của dịch vụ.
			Tuy nhiên, bạn cũng biết và thừa nhận rằng Nền tảng không thể dự đoán và bảo vệ chống lại các rủi ro kỹ thuật và
			các rủi ro khác bất cứ lúc nào hoặc mọi lúc, bao gồm nhưng không giới hạn ở sự gián đoạn hoặc bất thường trong
			dịch vụ có thể xảy ra do bất khả kháng, nguyên nhân mạng, lỗi dịch vụ của bên thứ ba, v.v., khi những nỗ lực
			thương mại lớn nhất của Nền tảng được cải thiện mà không có nghĩa vụ phải chịu bất kỳ trách nhiệm pháp lý nào đối
			với bạn hoặc bên thứ ba khác.
		</p>
		<p>2, Nền tảng có thể tự thực hiện bảo trì thời gian chết, nâng cấp hệ thống và điều chỉnh, nếu kết quả là bạn không
			thể sử dụng Dịch vụ đúng cách, bạn đồng ý rằng Nền tảng không phải chịu trách nhiệm pháp lý.</p>
		<p>3 Trong mọi trường hợp, Nền tảng không chịu trách nhiệm về bất kỳ thiệt hại gián tiếp, trừng phạt, ngẫu nhiên
			hoặc hình phạt nào. Và toàn bộ trách nhiệm của Nền tảng đối với bạn, vì bất kỳ lý do gì hoặc bằng cách nào, không
			bao giờ vượt quá số tiền bạn phải trả cho việc sử dụng này.</p>
		<H3>Trách nhiệm pháp lý</H3>
		<p>Trong trường hợp bạn vi phạm Thỏa thuận này, Nền tảng có quyền thực hiện các biện pháp quản lý đối với bạn hoặc
			tài
			khoản của bạn, tùy thuộc vào tình hình cụ thể và mức độ nguy hiểm của hành vi của bạn, bao gồm nhưng không giới
			hạn
			ở cảnh báo, khấu trừ hoặc xóa tài khoản của bạn, hạn chế toàn bộ hoặc một phần chức năng của tài khoản của bạn,
			đóng
			tài khoản của bạn trong thời gian ngắn hoặc vĩnh viễn, v.v.
		</p>
		<p>
			Bạn hiểu và thừa nhận rằng nếu bên thứ ba bị nghi ngờ sử dụng tài khoản của bạn để thực hiện hành vi vi phạm hoặc
			vi phạm thỏa thuận này, để đảm bảo quyền và lợi ích hợp pháp của các nạn nhân tiềm năng và người dùng khác, để duy
			trì trật tự sinh thái của Nền tảng và ngăn chặn thiệt hại mở rộng hơn nữa, Nền tảng cũng có quyền áp dụng các biện
			pháp quản lý tài khoản của bạn theo Thỏa thuận này và các quy tắc liên quan đến Nền tảng, bạn không nên yêu cầu
			Nền tảng chịu trách nhiệm pháp lý.
		</p>
		<p>
			Nếu bạn thực hiện hoặc bị nghi ngờ thực hiện hành vi bất hợp pháp hoặc sai trái bên ngoài Nền tảng để Nền tảng có
			cơ sở hợp lý để tin rằng bạn đã hoặc sắp vi phạm Thỏa thuận này hoặc các quy tắc khác của Nền tảng mà bạn có thể
			sử dụng (ví dụ: Thỏa thuận Dịch vụ Người dùng UniLive, Công ước Tự điều chỉnh Cộng đồng, v.v.), Nền tảng cũng có
			quyền thực hiện các biện pháp quản lý được thỏa thuận tại khoản 1 của Điều này đối với bạn và bạn không nên yêu
			cầu Nền tảng phải chịu trách nhiệm pháp lý.
		</p>



	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>